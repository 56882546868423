import React from 'react'
import { withTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import Select from 'react-select'

const getCustomStyles = (theme, userDarkMode) => {
  return {
    option: (provided, state) => ({
      ...provided,
      color: userDarkMode ? '#FAFAFA' : 'rgba(0, 0, 0, 0.56)',
      textAling: 'Left',
      backgroundColor: userDarkMode ? '#191B20' : theme.colors.white,
      fontSize: 16,
      paddingRight: 20,
      paddingLeft: 20,
      textAlign: 'Left',
      zIndex: 999,
      '&:hover': {
        backgroundColor: userDarkMode
          ? theme.colors.secondary
          : theme.colors.border,
      },
    }),
    control: (provided, state) => {
      return {
        ...provided,
        backgroundColor: userDarkMode
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
        border: `0px solid transparent !important`,
        marginBottom: 2,
        marginTop: 2,
        paddingRight: 10,
        paddingLeft: 10,
        zIndex: 999,
        fontSize: 16,
        width: '130px',
        outline: 'none',
        height: 44,
        minHeight: 20,
        borderRadius: 22,
        boxShadow: 'none',
        color: userDarkMode
          ? 'rgba(255, 255, 255, 0.56)'
          : 'rgba(0, 0, 0, 0.56)',
        '&:hover': {
          backgroundColor: userDarkMode
            ? theme.colors.secondary
            : theme.colors.border,
        },
      }
    },
    menu: provided => ({
      ...provided,
      borderRadius: 12,
      zIndex: 999,
      maxWidth: 200
    }),
    menuList: provided => ({
      ...provided,
      backgroundColor: userDarkMode ? '#191B20' : theme.colors.white,
      borderRadius: 10,
    }),
    singleValue: (provided, state) => {
      const color = 'inherit'
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, color, opacity, transition }
    },
  }
}

const SelectStyled = ({ theme, name = 'item', options, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  return (
    <Select
      styles={getCustomStyles(theme, userDarkMode)}
      defaultValue={options[0]}
      name={name}
      options={options}
      {...rest}
    />
  )
}

export default withTheme(SelectStyled)
